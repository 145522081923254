<template>
  <v-container class="pt-0">
    <div class="d-flex justify-end mb-4">
      <BaseButton
        small
        color="secondary"
        title="Novo cadastro"
        icon="fa-solid fa-plus"
        @click="create"
      />
    </div>

    <BaseTableList>
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          hide-default-footer
          item-key="partnerId"
          :headers="headers"
          :items="items"
        >
          <template v-slot:[`item.thumbnail`]="{ item }">
            <v-avatar size="75" tile>
              <v-img :src="item.thumbnail" :alt="item.partnerName" contain />
            </v-avatar>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="success"
                  class="mr-2"
                  v-on="on"
                  @click="edit(item)"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <Edit
      v-if="dialog"
      :title="modalTitle"
      :dialog="dialog"
      :isEdit="editMode"
      :userId="userId"
      :partnerId="selectedPartner"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { PartnerService } from '@/services/api/partners';

export default {
  components: {
    Edit: () => import('./dialog/dialog-partner')
  },

  props: {
    userId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    dialog: false,
    selectedPartner: '',
    modalTitle: '',
    editMode: false,
    items: [],
    headers: [
      { text: '', value: 'thumbnail' },
      { text: 'Nome', value: 'partnerName' },
      { text: 'Login', value: 'login' },
      { text: '', value: 'action' }
    ]
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      try {
        const partnerService = new PartnerService();
        const { status, data } = await partnerService.getPartnersUser(
          this.userId
        );

        if (status === 200) {
          this.items = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    create() {
      this.selectedPartner = '';
      this.editMode = false;
      this.modalTitle = 'Adicionar parceiro';
      this.dialog = !this.dialog;
    },

    edit(item) {
      this.selectedPartner = item.partnerId;
      this.editMode = true;
      this.modalTitle = 'Visualizar parceiro';
      this.dialog = !this.dialog;
    },

    closeModal(create) {
      this.dialog = false;

      if (create) this.search();
    }
  }
};
</script>
